import React, { useEffect, useState } from "react";

import "./slider.styl";

const Slider = ({ children }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setIndex((i) => i + 1), 6000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (index === children.length) setTimeout(() => setIndex(0), 3000);
  }, [index, children]);

  console.log(index);

  const style = {
    transform: `translateX(-${100 * index}%)`,
    transitionDuration: "0.5s",
  };

  if (index === 0) style.transitionDuration = "0s";

  return (
    <div className="slider">
      <div className="slider__inner" style={style}>
        {children}
        {children[0]}
      </div>
    </div>
  );
};

export default Slider;
