import { graphql } from "gatsby";
import Image from "gatsby-image";

import React from "react";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import Slider from "../components/slider/slider";

import digital from "src/images/assets/digital.svg";
import editorial from "src/images/assets/editorial.svg";
import packaging from "src/images/assets/packaging.svg";

export const pageQuery = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query($language: String!) {
    site {
      siteMetadata {
        email
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    digital_ES: file(relativePath: { eq: "banner/digital-es.jpg" }) {
      ...fluidImage
    }
    digital_EN: file(relativePath: { eq: "banner/digital-en.jpg" }) {
      ...fluidImage
    }
    editorial_ES: file(relativePath: { eq: "banner/editorial-es.jpg" }) {
      ...fluidImage
    }
    editorial_EN: file(relativePath: { eq: "banner/editorial-en.jpg" }) {
      ...fluidImage
    }
    packaging_ES: file(relativePath: { eq: "banner/packaging-es.jpg" }) {
      ...fluidImage
    }
    packaging_EN: file(relativePath: { eq: "banner/packaging-en.jpg" }) {
      ...fluidImage
    }
  }
`;

const Index = ({ data }) => {
  const { language } = useI18next();

  const banners = [
    {
      key: "digital",
      media: language === "en" ? data.digital_EN : data.digital_ES,
    },
    {
      key: "editorial",
      media: language === "en" ? data.editorial_EN : data.editorial_ES,
    },
    {
      key: "packaging",
      media: language === "en" ? data.packaging_EN : data.packaging_ES,
    },
  ];

  return (
    <Layout pagename="home">
      <SEO />
      <Slider>
        {banners.map(({ key, media }) => (
          <Image key={key} fluid={media.childImageSharp.fluid} />
        ))}
      </Slider>

      <div className="home__icons">
        <Link to="/services/web/" className="home__icon">
          <img className="home__icon-image" src={digital} alt="digital" />
          <span className="home__icon-label">
            <Trans>Digital</Trans>
          </span>
        </Link>
        <Link to="/services/editorial/" className="home__icon">
          <img className="home__icon-image" src={editorial} alt="editorial" />
          <span className="home__icon-label">
            <Trans>Editorial</Trans>
          </span>
        </Link>
        <Link to="/services/graphic/" className="home__icon">
          <img className="home__icon-image" src={packaging} alt="packaging" />
          <span className="home__icon-label">
            <Trans>Packaging</Trans>
          </span>
        </Link>
      </div>

      <div className="home__text">
        <Trans>
          Estudio 94 es un estudio de diseño integral, que nace con el objetivo
          de proveer servicios de diseño gráfico, web y márketing a clientes
          particulares o corporativos, a precios accesibles para todos. Somos un
          equipo dinámico y siempre tenemos ganas de afrontar nuevos retos.
        </Trans>
      </div>
    </Layout>
  );
};

export default Index;
